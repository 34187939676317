<template>
  <van-popup :show="show" :position="menuPosition ? 'left' : 'bottom'" :class="menuPosition ? 'left-menu' : 'bottom-menu'" @close="show = false">
    <div class="user-pane">
      <van-image round :src="headImg ? headImg : require('@/assets/images/personal/user.png')" class="user-icon"/>
      <div class="user-text">{{ account }}</div>
    </div>
    <div class="menu-pane">
      <van-grid :column-num="menuPosition ? '2' : '4'" square :border="false">
        <van-grid-item v-for="(item,index) in list" :key="index" :icon="item.icon" :text="item.title"
                       @click="gotoUrl(item.url)"/>
        <van-grid-item :icon="require('@/assets/images/float/change-icon.png')" text="切换账号" @click="logout"/>
      </van-grid>
    </div>
  </van-popup>
  <div v-show="!show" class="float_info" @click="onBtnClicked" ref="floatButton"
       @mousemove="mouseMove" @mousedown="mouseDown" @mouseleave="mouseUp" @mouseup="mouseUp"
       :style="{'width': itemWidth + 'px', 'height': itemHeight + 'px', 'left': left + 'px', 'top': top + 'px'}">
  </div>
</template>

<script>

import {nextTick, onMounted, ref} from "vue";
import {Popup, Icon, Grid, GridItem,Image as VanImage,Dialog} from "vant";
import {menuConfig} from "@/api/tool";
import {callLogout, openUrl} from "@/utils/callback";
import {isLandscape} from "@/utils/devices";
import {useStore} from "vuex";

export default {
  components: {
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [VanImage.name]: VanImage,
    [Dialog.Component.name]: Dialog.Component,
  },
  name: "float",
  props: {
    coefficientHeight: {    // 从上到下距离比例
      type: Number,
      default: 0.1
    }
  },
  setup() {
    // 悬浮球宽高
    const itemWidth = ref(40)
    const itemHeight = ref(40)
    // 悬浮球位置比例
    const coefficientHeight = ref(0.2);
    // 屏幕宽高
    const clientWidth = ref(document.documentElement.clientWidth)
    const clientHeight = ref(document.documentElement.clientHeight)

    // 悬浮球位置
    const left = ref(0)
    const top = ref(clientHeight.value * coefficientHeight.value)

    // 悬浮球实例
    const floatButton = ref()
    const isMove = ref(false);
    const isTouch = ref(false);
    // 菜单列表
    const list = ref([])
    const show = ref(false)


    // 点击悬浮球方法
    const onBtnClicked = () => {
      if (isMove.value) {
        return;
      }
      //菜单配置
      menuConfig().then(res => {
        list.value = res.data;
      })
      show.value = true
    };

    //url跳转
    const gotoUrl = (url) => {
      show.value = false
      openUrl(url);
    }

    // 移动悬浮球
    const moveFloat = (float_X, float_Y) => {
      left.value = float_X - 20
      top.value = float_Y - 20
      isMove.value = true
    }

    // 鼠标移动
    const mouseMove = (event) => {
      if (isTouch.value) {
        moveFloat(event.clientX, event.clientY)
      }
    }
    // 鼠标点击
    const mouseDown = () => {
      floatButton.value.style.transition = 'none';
      isMove.value = false
      isTouch.value = true
    }
    // 鼠标结束
    const mouseUp = () => {
      isTouch.value = false
      floatButton.value.style.transition = 'all 1s';
      if (isMove.value) {
        // 移动过位置 重新设定位置及隐藏
        if (top.value < 0) {
          top.value = 0
        }
        // 移动位置结束
        if (top.value > clientHeight.value - itemHeight.value) {
          top.value = clientHeight.value - itemHeight.value
        }

        if (left.value < clientWidth.value / 2) {
          rotateFloatLeft()
          left.value = 0
        } else {
          rotateFloatRight()
          left.value = clientWidth.value - itemWidth.value
        }

      }
    }

    onMounted(() => {
      nextTick(() => {
        floatButton.value.addEventListener("touchstart", () => {
          mouseDown();
        })
        // 在拖拽的过程中，组件应该跟随手指的移动而移动。
        floatButton.value.addEventListener("touchmove", (e) => {
          let touch = e.targetTouches[0]
          moveFloat(touch.clientX, touch.clientY)
        })
        // 拖拽结束以后，重新调整组件的位置并重新设置过度动画。
        floatButton.value.addEventListener("touchend", () => {
          mouseUp()
        });
      })
    })

    //right旋转&&公共方法
    const rotateFloatRight = () => {
      setTimeout(() => {
        floatButton.value.style.transform = 'rotate(360deg)';
        floatButton.value.style.transition = 'all 2s';
        left.value = document.documentElement.clientWidth - 30;
        // 2秒后回正图像
        setTimeout(() => {
          floatButton.value.style.transform = 'rotate(0deg)';
          floatButton.value.style.transition = 'none';
        }, 2000);
      }, 2000);
    };

    //left旋转&&公共方法
    const rotateFloatLeft = () => {
      setTimeout(() => {
        floatButton.value.style.transform = 'rotate(-360deg)';
        floatButton.value.style.transition = 'all 2s';
        left.value = -10;
        // 2秒后回正图像
        setTimeout(() => {
          floatButton.value.style.transform = 'rotate(0deg)';
          floatButton.value.style.transition = 'none';
        }, 2000);
      }, 2000);
    };

    rotateFloatLeft()

    //菜单横竖屏显示
    const menuPosition = ref(isLandscape())
    const store = useStore()
    const account = ref(store.state.account)
    const headImg = ref(store.state.head_img)

    //切换账号
    const logout = () =>{
      Dialog.confirm({
        message: '确定要切换账号吗?',
        confirmButtonText: '返回游戏',
        cancelButtonText: '离开',
      }).catch(() => {
        show.value = false
        // store.commit('logout')
        callLogout()
      });
    }

    return {
      itemWidth,
      itemHeight,
      clientWidth,
      clientHeight,
      left,
      top,
      floatButton,
      show,
      list,
      menuPosition,
      account,
      headImg,

      onBtnClicked,
      gotoUrl,
      moveFloat,
      mouseMove,
      mouseUp,
      mouseDown,
      rotateFloatRight,
      rotateFloatLeft,
      logout
    }
  }
}
</script>

<style>
.float_info {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("../assets/images/float_icon.png");
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
}

/*菜单栏*/
.menu-pane .van-grid-item__content{
  background:none;
}
.menu-pane.van-hairline--top::after,.menu-pane .van-grid-item__content::after{
  display: none;
}
/*左侧吸附*/
.left-menu{
  width:110px;
  height:100%;
  background: rgba(29, 33, 41, 0.75);
  padding-top:10px;
  box-sizing: border-box;
}
.left-menu .user-pane{
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom:5px;
}

.left-menu .user-icon{
  width:28px;
  height:28px;
  border-radius: 180%;
  overflow: hidden;
}
.left-menu .user-text{
  font-size: 9px;
  font-weight: 400;
  color: #FFFFFF;
  height:12px;
  line-height: 12px;
}

.left-menu .van-grid-item__icon{
  font-size:24px;
}
.left-menu .van-grid-item__text{
  height: 10px;
  font-size: 8px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 10px;
  margin-top:5px;
}
/*底部吸附*/
.bottom-menu{
  width:100%;
  background: rgba(29, 33, 41, 0.75);
  padding:10px;
  box-sizing: border-box;
  overflow: unset;
}
.bottom-menu .user-pane{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom:8px;
  position: relative;
  margin-top:-38px;
}
.bottom-menu .user-icon{
  width:57px;
  height:57px;
  border-radius: 180%;
  margin:3px;
  overflow: hidden;
}
.bottom-menu .user-text{
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  height:25px;
  line-height: 25px;
}
.bottom-menu .van-grid-item__icon{
  font-size:45px;
}
.bottom-menu .van-grid-item__text{
  height: 20px;
  font-size: 14px;
  line-height: 20px;
}
</style>

