//工具请求模块
import request from '../utils/request'
//实名认证状态
export const realAuthStatus = (data) => {
    return request({
        method: 'post',
        url: '/tool/real-auth-status',
        data,
    })
}
//初始化接口
export const tabsInit = (data) => {
    return request({
        method: 'post',
        url: '/tool/init',
        data,
    })
}
//悬浮球菜单配置
export const menuConfig = (data) => {
    return request({
        method: 'post',
        url: '/tool/menu-config',
        data,
    })
}
//帮助中心
export function helpCenter(data){
    return request({
        method: 'post',
        url: '/tool/help-center',
        data,
    })
}
//消息列表
export function noticeList(data){
    return request({
        method: 'post',
        url: '/tool/notice-list',
        data,
    })
}
//消息列表
export function noticeDetail(notice_id){
    return request({
        method: 'post',
        url: '/tool/notice-detail',
        data:{notice_id},
    })
}
